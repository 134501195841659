export const initialCoordinates375x812 = [
  { x: 60, y: 42, id: 1 },
  { x: 105, y: 110, id: 2 },
  { x: 46, y: 125, id: 3 },
  { x: 190, y: 44, id: 4 },
  { x: 245, y: 120, id: 5 },
  { x: 342, y: 82, id: 6 },
  { x: 415, y: 77, id: 7 },
  { x: 327, y: 160, id: 8 },
  { x: 400, y: 130, id: 9 },
  { x: 655, y: 28, id: 10 },
  { x: 630, y: 100, id: 11 },
  { x: 685, y: 130, id: 12 },
  { x: 754, y: 117, id: 13 },
  { x: 890, y: 49, id: 14 },
  { x: 870, y: 126, id: 15 },
  { x: 952, y: 92, id: 16 },
  { x: 945, y: 231, id: 32 },
  { x: 883, y: 250, id: 31 },
  { x: 1008, y: 202, id: 33 },
  { x: 1106, y: 30, id: 17 },
  { x: 1037, y: 76, id: 18 },
  { x: 1139, y: 79, id: 19 },
  { x: 1074, y: 145, id: 34 },
  { x: 1126, y: 198, id: 35 },
  { x: 1092, y: 237, id: 52 },
  { x: 1162, y: 260, id: 52 },
  { x: 1135, y: 285, id: 53 },
  { x: 1080, y: 280, id: 50 },
  { x: 1060, y: 310, id: 51 },
  { x: 1248, y: 110, id: 20 },
  { x: 1268, y: 168, id: 37 },
  { x: 1238, y: 165, id: 36 },
  { x: 1270, y: 240, id: 38 },
  { x: 1233, y: 270, id: 39 },
  { x: 1187, y: 333, id: 54 },
  { x: 1230, y: 330, id: 102 },
  { x: 1084, y: 371, id: 86 },
  { x: 1030, y: 351, id: 83 },
  { x: 1262, y: 355, id: 104 },
  { x: 1236, y: 385, id: 101 },
  { x: 1225, y: 475, id: 103 },
  { x: 1165, y: 470, id: 97 },
  { x: 1108, y: 480, id: 92 },
  { x: 1115, y: 513, id: 99 },
  { x: 1230, y: 625, id: 117 },
  { x: 1090, y: 635, id: 118 },
  { x: 1009, y: 573, id: 87 },
  { x: 1079, y: 563, id: 100 },
  // { x: 1013, y: 524, id: 96 },
  { x: 1120, y: 715, id: 123 },
  { x: 1218, y: 740, id: 125 },
  { x: 1132, y: 742, id: 124 },
  { x: 1263, y: 734, id: 128 },
  { x: 1247, y: 780, id: 127 },
  { x: 1137, y: 784, id: 126 },
  { x: 1028, y: 744, id: 121 },
  { x: 918, y: 777, id: 119 },
  { x: 998, y: 774, id: 120 },
  { x: 915, y: 735, id: 115 },
  { x: 890, y: 680, id: 114 },
  { x: 830, y: 710, id: 113 },
  { x: 855, y: 775, id: 116 },
  { x: 910, y: 480, id: 93 },
  { x: 900, y: 523, id: 95 },
  { x: 840, y: 543, id: 122 },
  { x: 945, y: 525, id: 94 },
  { x: 1015, y: 470, id: 94 },
  { x: 777, y: 663, id: 112 },
  { x: 760, y: 774, id: 144 },
  { x: 720, y: 690, id: 143 },
  { x: 43, y: 250, id: 21 },
  { x: 100, y: 222, id: 22 },
  { x: 208, y: 220, id: 23 },
  { x: 287, y: 210, id: 25 },
  { x: 415, y: 223, id: 27 },
  { x: 475, y: 193, id: 28 },
  { x: 585, y: 221, id: 29 },
  { x: 590, y: 271, id: 45 },
  { x: 585, y: 351, id: 46 },
  { x: 718, y: 283, id: 47 },
  { x: 806, y: 233, id: 30 },
  { x: 826, y: 289, id: 49 },
  { x: 490, y: 250, id: 44 },
  { x: 338, y: 215, id: 26 },
  { x: 242, y: 288, id: 42 },
  { x: 49, y: 380, id: 40 },
  { x: 106, y: 312, id: 41 },
  { x: 165, y: 332, id: 72 },
  { x: 175, y: 422, id: 145 },
  { x: 98, y: 415, id: 71 },
  { x: 345, y: 302, id: 98 },
  { x: 360, y: 350, id: 43 },
  { x: 400, y: 353, id: 55 },
  { x: 450, y: 378, id: 56 },
  { x: 380, y: 398, id: 57 },
  { x: 408, y: 390, id: 58 },
  { x: 466, y: 398, id: 60 },
  { x: 485, y: 380, id: 61 },
  { x: 505, y: 400, id: 59 },
  { x: 505, y: 378, id: 62 },
  { x: 530, y: 358, id: 63 },
  { x: 557, y: 424, id: 152 },
  { x: 285, y: 350, id: 111 },
  { x: 557, y: 374, id: 151 },
  { x: 597, y: 374, id: 64 },
  { x: 630, y: 389, id: 65 },
  { x: 640, y: 369, id: 149 },
  { x: 686, y: 409, id: 66 },
  { x: 693, y: 354, id: 48 },
  { x: 727, y: 349, id: 48 },
  { x: 884, y: 417, id: 68 },
  { x: 820, y: 413, id: 81 },
  { x: 935, y: 413, id: 82 },
  { x: 808, y: 473, id: 80 },
  { x: 788, y: 493, id: 68 },
  { x: 623, y: 513, id: 78 },
  { x: 627, y: 483, id: 24 },
  { x: 696, y: 459, id: 150 },
  { x: 666, y: 451, id: 150 },
  { x: 567, y: 516, id: 79 },
  { x: 494, y: 523, id: 77 },
  { x: 460, y: 518, id: 76 },
  { x: 407, y: 469, id: 74 },
  { x: 343, y: 460, id: 73 },
  { x: 330, y: 525, id: 73 },
  { x: 463, y: 473, id: 75 },
  { x: 234, y: 522, id: 85 },
  { x: 70, y: 530, id: 84 },
  { x: 43, y: 477, id: 68 },
  { x: 69, y: 437, id: 69 },
  { x: 118, y: 479, id: 68 },
  { x: 88, y: 438, id: 70 },
  { x: 16, y: 502, id: 67 },
  { x: 30, y: 578, id: 105 },
  { x: 170, y: 565, id: 87 },
  { x: 321, y: 550, id: 88 },
  { x: 468, y: 580, id: 89 },
  { x: 1133, y: 436, id: 91 },
  { x: 817, y: 572, id: 90 },
  { x: 626, y: 665, id: 142 },
  { x: 552, y: 678, id: 139 },
  { x: 427, y: 734, id: 136 },
  { x: 426, y: 766, id: 141 },
  { x: 498, y: 654, id: 137 },
  { x: 555, y: 596, id: 138 },
  { x: 258, y: 640, id: 132 },
  { x: 225, y: 720, id: 133 },
  { x: 249, y: 739, id: 134 },
  { x: 285, y: 609, id: 131 },
  // { x: 146, y: 341, id: 135 },
  { x: 149, y: 709, id: 130 },
  { x: 107, y: 640, id: 110 },
  { x: 35, y: 662, id: 129 },
  { x: 22, y: 700, id: 140 },
  { x: 192, y: 612, id: 106 },
  { x: 378, y: 606, id: 107 },
  { x: 331, y: 619, id: 108 },
  { x: 381, y: 630, id: 109 },
  { x: 430, y: 640, id: 146 }
];
