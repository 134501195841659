export const initialCoordinates1440x900 = [
  { x: 80, y: 70, id: 1 },
  { x: 123, y: 130, id: 2 },
  { x: 52, y: 151, id: 3 },
  { x: 44, y: 275, id: 21 },
  { x: 120, y: 245, id: 22 },
  { x: 121, y: 318, id: 41 },
  { x: 57, y: 440, id: 40 },
  { x: 118, y: 469, id: 71 },
  { x: 197, y: 446, id: 72 },
  { x: 230, y: 244, id: 23 },
  { x: 233, y: 50, id: 4 },
  { x: 374, y: 107, id: 6 },
  { x: 363, y: 176, id: 8 },
  { x: 464, y: 95, id: 7 },
  { x: 486, y: 180, id: 9 },
  { x: 533, y: 108, id: 11 },
  { x: 455, y: 260, id: 27 },
  { x: 532, y: 214, id: 28 },
  { x: 375, y: 238, id: 26 },
  { x: 355, y: 336, id: 98 },
  { x: 403, y: 390, id: 43 },
  { x: 314, y: 235, id: 25 },
  { x: 271, y: 333, id: 42 },
  { x: 420, y: 447, id: 57 },
  { x: 457, y: 447, id: 58 },
  { x: 436, y: 403, id: 55 },
  { x: 439, y: 340, id: 25 },
  { x: 498, y: 432, id: 56 },
  { x: 515, y: 448, id: 60 },
  { x: 535, y: 426, id: 61 },
  { x: 554, y: 451, id: 59 },
  { x: 558, y: 421, id: 62 },
  { x: 625, y: 405, id: 151 },
  { x: 315, y: 390, id: 111 },
  { x: 592, y: 377, id: 44 },
  { x: 539, y: 279, id: 44 },
  { x: 660, y: 313, id: 45 },
  { x: 652, y: 255, id: 29 },
  { x: 642, y: 386, id: 46 },
  { x: 683, y: 433, id: 65 },
  { x: 710, y: 416, id: 149 },
  { x: 664, y: 416, id: 64 },
  { x: 272, y: 135, id: 5 },
  { x: 754, y: 146, id: 12 },
  { x: 766, y: 392, id: 48 },
  { x: 775, y: 455, id: 66 },
  { x: 801, y: 398, id: 48 },
  { x: 790, y: 316, id: 47 },
  { x: 827, y: 103, id: 13 },
  { x: 765, y: 19, id: 10 },
  { x: 894, y: 254, id: 30 },
  { x: 910, y: 320, id: 49 },
  { x: 979, y: 278, id: 31 },
  { x: 984, y: 168, id: 15 },
  { x: 986, y: 59, id: 14 },
  { x: 1045, y: 250, id: 32 },
  { x: 1112, y: 228, id: 33 },
  { x: 1139, y: 85, id: 18 },
  { x: 1227, y: 35, id: 17 },
  { x: 1264, y: 90, id: 19 },
  { x: 1173, y: 159, id: 34 },
  { x: 1103, y: 107, id: 16 },
  { x: 1189, y: 259, id: 52 },
  { x: 1248, y: 224, id: 35 },
  { x: 1199, y: 419, id: 86 },
  { x: 1141, y: 390, id: 83 },
  { x: 1261, y: 327, id: 53 },
  { x: 1196, y: 329, id: 50 },
  { x: 1177, y: 358, id: 51 },
  { x: 1387, y: 128, id: 20 },
  { x: 1409, y: 182, id: 37 },
  { x: 1380, y: 182, id: 36 },
  { x: 1354, y: 297, id: 39 },
  { x: 1411, y: 269, id: 38 },
  { x: 1375, y: 373, id: 102 },
  { x: 1406, y: 412, id: 104 },
  { x: 1373, y: 435, id: 101 },
  { x: 1311, y: 369, id: 54 },
  { x: 1025, y: 452, id: 82 },
  { x: 977, y: 450, id: 68 },
  { x: 1362, y: 557, id: 103 },
  { x: 1260, y: 545, id: 97 },
  { x: 1230, y: 536, id: 92 },
  { x: 1016, y: 543, id: 93 },
  { x: 1150, y: 527, id: 94 },
  // { x: 1118, y: 590, id: 96 },
  { x: 1058, y: 598, id: 94 },
  { x: 995, y: 590, id: 95 },
  { x: 1241, y: 578, id: 99 },
  { x: 1215, y: 693, id: 118 },
  { x: 1195, y: 657, id: 100 },
  { x: 1133, y: 660, id: 87 },
  { x: 941, y: 656, id: 90 },
  { x: 780, y: 638, id: 90 },
  { x: 940, y: 621, id: 91 },
  { x: 797, y: 765, id: 143 },
  { x: 874, y: 749, id: 112 },
  { x: 977, y: 763, id: 114 },
  { x: 1015, y: 815, id: 115 },
  { x: 914, y: 789, id: 113 },
  { x: 939, y: 861, id: 116 },
  { x: 840, y: 872, id: 144 },
  { x: 1020, y: 885, id: 119 },
  { x: 1136, y: 879, id: 120 },
  { x: 1140, y: 836, id: 121 },
  { x: 1268, y: 793, id: 123 },
  { x: 1257, y: 830, id: 124 },
  { x: 1361, y: 839, id: 125 },
  { x: 1410, y: 829, id: 128 },
  { x: 1398, y: 878, id: 127 },
  { x: 1288, y: 878, id: 127 },
  { x: 1355, y: 697, id: 117 },
  { x: 1375, y: 731, id: 117 },
  { x: 921, y: 482, id: 81 },
  { x: 894, y: 533, id: 80 },
  { x: 874, y: 547, id: 68 },
  { x: 703, y: 528, id: 24 },
  { x: 684, y: 575, id: 78 },
  { x: 630, y: 572, id: 79 },
  { x: 561, y: 584, id: 77 },
  { x: 630, y: 485, id: 152 },
  { x: 489, y: 510, id: 25 },
  { x: 447, y: 537, id: 74 },
  { x: 413, y: 480, id: 150 },
  { x: 771, y: 510, id: 150 },
  { x: 737, y: 500, id: 150 },
  { x: 698, y: 741, id: 142 },
  { x: 616, y: 736, id: 139 },
  { x: 605, y: 662, id: 138 },
  { x: 560, y: 730, id: 137 },
  { x: 477, y: 811, id: 136 },
  { x: 470, y: 870, id: 141 },
  { x: 418, y: 730, id: 108 },
  { x: 385, y: 725, id: 107 },
  { x: 370, y: 700, id: 109 },
  { x: 473, y: 731, id: 146 },
  { x: 525, y: 657, id: 89 },
  { x: 508, y: 580, id: 76 },
  { x: 364, y: 610, id: 88 },
  { x: 355, y: 582, id: 73 },
  { x: 380, y: 519, id: 73 },
  { x: 515, y: 528, id: 75 },
  { x: 320, y: 702, id: 131 },
  { x: 291, y: 717, id: 132 },
  { x: 210, y: 682, id: 106 },
  { x: 120, y: 702, id: 110 },
  { x: 166, y: 782, id: 130 },
  { x: 246, y: 808, id: 133 },
  { x: 293, y: 823, id: 134 },
  { x: 50, y: 737, id: 129 },
  { x: 30, y: 767, id: 140 },
  { x: 258, y: 589, id: 85 },
  { x: 205, y: 633, id: 87 },
  { x: 77, y: 596, id: 84 },
  { x: 133, y: 532, id: 68 },
  { x: 80, y: 480, id: 69 },
  { x: 100, y: 494, id: 70 },
  { x: 50, y: 540, id: 68 },
  { x: 30, y: 639, id: 105 },
  { x: 18, y: 565, id: 67 },
];
