export const initialCoordinates360x640 = [
  { x: 46, y: 35, id: 1 },
  { x: 80, y: 80, id: 2 },
  { x: 36, y: 105, id: 3 },
  { x: 150, y: 28, id: 4 },
  { x: 195, y: 96, id: 5 },
  { x: 265, y: 69, id: 6 },
  { x: 328, y: 65, id: 7 },
  { x: 256, y: 124, id: 8 },
  { x: 340, y: 125, id: 9 },
  { x: 515, y: 28, id: 10 },
  { x: 503, y: 82, id: 11 },
  { x: 559, y: 73, id: 12 },
  { x: 590, y: 94, id: 13 },
  { x: 700, y: 39, id: 14 },
  { x: 688, y: 122, id: 15 },
  { x: 772, y: 74, id: 16 },
  { x: 744, y: 181, id: 32 },
  { x: 697, y: 196, id: 31 },
  { x: 794, y: 162, id: 33 },
  { x: 876, y: 24, id: 17 },
  { x: 812, y: 60, id: 18 },
  { x: 898, y: 62, id: 19 },
  { x: 838, y: 113, id: 34 },
  { x: 878, y: 158, id: 35 },
  { x: 857, y: 187, id: 52 },
  { x: 921, y: 220, id: 52 },
  { x: 884, y: 215, id: 53 },
  { x: 836, y: 218, id: 50 },
  { x: 836, y: 248, id: 51 },
  { x: 985, y: 88, id: 20 },
  { x: 994, y: 125, id: 37 },
  { x: 965, y: 139, id: 36 },
  { x: 993, y: 180, id: 38 },
  { x: 968, y: 217, id: 39 },
  { x: 943, y: 258, id: 54 },
  { x: 974, y: 260, id: 102 },
  { x: 854, y: 301, id: 86 },
  { x: 810, y: 280, id: 83 },
  { x: 995, y: 275, id: 104 },
  { x: 975, y: 300, id: 101 },
  { x: 970, y: 376, id: 103 },
  { x: 920, y: 366, id: 97 },
  { x: 874, y: 386, id: 92 },
  { x: 902, y: 425, id: 99 },
  { x: 978, y: 480, id: 117 },
  { x: 860, y: 500, id: 118 },
  { x: 802, y: 461, id: 87 },
  { x: 849, y: 450, id: 100 },
  // { x: 803, y: 411, id: 96 },
  { x: 887, y: 565, id: 123 },
  { x: 957, y: 585, id: 125 },
  { x: 898, y: 580, id: 124 },
  { x: 995, y: 575, id: 128 },
  { x: 990, y: 605, id: 127 },
  { x: 895, y: 607, id: 126 },
  { x: 812, y: 595, id: 121 },
  { x: 719, y: 608, id: 119 },
  { x: 769, y: 603, id: 120 },
  { x: 710, y: 569, id: 115 },
  { x: 708, y: 525, id: 114 },
  { x: 655, y: 556, id: 113 },
  { x: 657, y: 600, id: 116 },
  { x: 715, y: 380, id: 93 },
  { x: 708, y: 413, id: 95 },
  { x: 648, y: 423, id: 122 },
  { x: 748, y: 415, id: 94 },
  { x: 820, y: 380, id: 94 },
  { x: 623, y: 520, id: 112 },
  { x: 612, y: 610, id: 144 },
  { x: 560, y: 540, id: 143 },
  { x: 33, y: 193, id: 21 },
  { x: 77, y: 172, id: 22 },
  { x: 164, y: 170, id: 23 },
  { x: 223, y: 166, id: 25 },
  { x: 320, y: 170, id: 27 },
  { x: 374, y: 155, id: 28 },
  { x: 462, y: 178, id: 29 },
  { x: 463, y: 213, id: 45 },
  { x: 443, y: 265, id: 46 },
  { x: 563, y: 223, id: 47 },
  { x: 633, y: 196, id: 30 },
  { x: 650, y: 226, id: 49 },
  { x: 371, y: 200, id: 44 },
  { x: 265, y: 170, id: 26 },
  { x: 192, y: 228, id: 42 },
  { x: 25, y: 292, id: 40 },
  { x: 82, y: 242, id: 41 },
  { x: 137, y: 272, id: 72 },
  { x: 137, y: 322, id: 145 },
  { x: 98, y: 328, id: 71 },
  { x: 275, y: 232, id: 98 },
  { x: 285, y: 268, id: 43 },
  { x: 316, y: 283, id: 55 },
  { x: 349, y: 298, id: 56 },
  { x: 287, y: 300, id: 57 },
  { x: 320, y: 310, id: 58 },
  { x: 366, y: 314, id: 60 },
  { x: 380, y: 284, id: 61 },
  { x: 400, y: 314, id: 59 },
  { x: 395, y: 302, id: 62 },
  { x: 415, y: 282, id: 63 },
  { x: 435, y: 332, id: 152 },
  { x: 220, y: 275, id: 111 },
  { x: 436, y: 290, id: 151 },
  { x: 470, y: 295, id: 64 },
  { x: 500, y: 303, id: 65 },
  { x: 496, y: 281, id: 149 },
  { x: 540, y: 321, id: 66 },
  { x: 540, y: 278, id: 48 },
  { x: 570, y: 278, id: 48 },
  { x: 686, y: 317, id: 68 },
  { x: 640, y: 330, id: 81 },
  { x: 732, y: 324, id: 82 },
  { x: 636, y: 370, id: 80 },
  { x: 622, y: 388, id: 68 },
  { x: 488, y: 402, id: 78 },
  { x: 491, y: 377, id: 24 },
  { x: 548, y: 360, id: 150 },
  { x: 515, y: 345, id: 150 },
  { x: 438, y: 402, id: 79 },
  { x: 388, y: 412, id: 77 },
  { x: 358, y: 407, id: 76 },
  { x: 320, y: 370, id: 74 },
  { x: 270, y: 360, id: 73 },
  { x: 260, y: 412, id: 73 },
  { x: 360, y: 372, id: 75 },
  { x: 184, y: 412, id: 85 },
  { x: 64, y: 420, id: 84 },
  { x: 94, y: 377, id: 68 },
  { x: 54, y: 348, id: 69 },
  { x: 70, y: 348, id: 70 },
  { x: 33, y: 378, id: 68 },
  { x: 12, y: 398, id: 67 },
  { x: 22, y: 458, id: 105 },
  { x: 131, y: 445, id: 87 },
  { x: 241, y: 430, id: 88 },
  { x: 358, y: 460, id: 89 },
  { x: 883, y: 351, id: 91 },
  { x: 677, y: 462, id: 90 },
  { x: 492, y: 526, id: 142 },
  { x: 432, y: 534, id: 139 },
  { x: 337, y: 574, id: 136 },
  { x: 335, y: 604, id: 141 },
  { x: 395, y: 514, id: 137 },
  { x: 435, y: 470, id: 138 },
  { x: 207, y: 499, id: 132 },
  { x: 212, y: 459, id: 131 },
  { x: 199, y: 579, id: 134 },
  { x: 169, y: 569, id: 133 },
  // { x: 146, y: 341, id: 135 },
  { x: 119, y: 549, id: 130 },
  { x: 84, y: 500, id: 110 },
  { x: 32, y: 515, id: 129 },
  { x: 22, y: 550, id: 140 },
  { x: 142, y: 472, id: 106 },
  { x: 288, y: 466, id: 107 },
  { x: 251, y: 482, id: 108 },
  { x: 291, y: 500, id: 109 },
  { x: 327, y: 510, id: 146 },
];
