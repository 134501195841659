export const initialCoordinates375x667 = [
  { x: 46, y: 35, id: 1 },
  { x: 82, y: 82, id: 2 },
  { x: 36, y: 105, id: 3 },
  { x: 150, y: 28, id: 4 },
  { x: 190, y: 90, id: 5 },
  { x: 280, y: 69, id: 6 },
  { x: 326, y: 57, id: 7 },
  { x: 270, y: 130, id: 8 },
  { x: 333, y: 108, id: 9 },
  { x: 545, y: 28, id: 10 },
  { x: 514, y: 85, id: 11 },
  { x: 569, y: 100, id: 12 },
  { x: 620, y: 97, id: 13 },
  { x: 733, y: 39, id: 14 },
  { x: 717, y: 100, id: 15 },
  { x: 792, y: 74, id: 16 },
  { x: 776, y: 181, id: 32 },
  { x: 730, y: 203, id: 31 },
  { x: 829, y: 167, id: 33 },
  { x: 911, y: 26, id: 17 },
  { x: 851, y: 59, id: 18 },
  { x: 904, y: 69, id: 19 },
  { x: 878, y: 117, id: 34 },
  { x: 928, y: 168, id: 35 },
  { x: 895, y: 192, id: 52 },
  { x: 964, y: 230, id: 52 },
  { x: 932, y: 235, id: 53 },
  { x: 896, y: 228, id: 50 },
  { x: 866, y: 258, id: 51 },
  { x: 1025, y: 95, id: 20 },
  { x: 1039, y: 135, id: 37 },
  { x: 1020, y: 149, id: 36 },
  { x: 1039, y: 200, id: 38 },
  { x: 1010, y: 220, id: 39 },
  { x: 974, y: 275, id: 54 },
  { x: 1014, y: 270, id: 102 },
  { x: 894, y: 311, id: 86 },
  { x: 847, y: 290, id: 83 },
  { x: 1039, y: 295, id: 104 },
  { x: 1015, y: 315, id: 101 },
  { x: 1010, y: 386, id: 103 },
  { x: 960, y: 386, id: 97 },
  { x: 914, y: 396, id: 92 },
  { x: 918, y: 426, id: 99 },
  { x: 1005, y: 508, id: 117 },
  { x: 900, y: 520, id: 118 },
  { x: 840, y: 481, id: 87 },
  { x: 885, y: 470, id: 100 },
  // { x: 832, y: 435, id: 96 },
  { x: 927, y: 585, id: 123 },
  { x: 1000, y: 609, id: 125 },
  { x: 933, y: 610, id: 124 },
  { x: 1040, y: 600, id: 128 },
  { x: 1040, y: 640, id: 127 },
  { x: 945, y: 642, id: 126 },
  { x: 836, y: 602, id: 121 },
  { x: 729, y: 640, id: 119 },
  { x: 809, y: 643, id: 120 },
  { x: 750, y: 599, id: 115 },
  { x: 733, y: 555, id: 114 },
  { x: 685, y: 585, id: 113 },
  { x: 697, y: 637, id: 116 },
  { x: 745, y: 390, id: 93 },
  { x: 738, y: 430, id: 95 },
  { x: 678, y: 450, id: 122 },
  { x: 778, y: 435, id: 94 },
  { x: 850, y: 390, id: 94 },
  { x: 639, y: 546, id: 112 },
  { x: 622, y: 635, id: 144 },
  { x: 590, y: 565, id: 143 },
  { x: 33, y: 200, id: 21 },
  { x: 85, y: 177, id: 22 },
  { x: 172, y: 178, id: 23 },
  { x: 233, y: 176, id: 25 },
  { x: 340, y: 185, id: 27 },
  { x: 394, y: 160, id: 28 },
  { x: 482, y: 178, id: 29 },
  { x: 485, y: 223, id: 45 },
  { x: 483, y: 290, id: 46 },
  { x: 590, y: 233, id: 47 },
  { x: 663, y: 200, id: 30 },
  { x: 680, y: 239, id: 49 },
  { x: 401, y: 205, id: 44 },
  { x: 278, y: 178, id: 26 },
  { x: 202, y: 238, id: 42 },
  { x: 40, y: 312, id: 40 },
  { x: 92, y: 252, id: 41 },
  { x: 144, y: 282, id: 72 },
  { x: 144, y: 342, id: 145 },
  { x: 94, y: 348, id: 71 },
  { x: 285, y: 242, id: 98 },
  { x: 295, y: 278, id: 43 },
  { x: 326, y: 283, id: 55 },
  { x: 356, y: 298, id: 56 },
  { x: 300, y: 315, id: 57 },
  { x: 326, y: 315, id: 58 },
  { x: 372, y: 317, id: 60 },
  { x: 388, y: 294, id: 61 },
  { x: 410, y: 320, id: 59 },
  { x: 405, y: 302, id: 62 },
  { x: 430, y: 292, id: 63 },
  { x: 445, y: 342, id: 152 },
  { x: 235, y: 285, id: 111 },
  { x: 456, y: 300, id: 151 },
  { x: 490, y: 310, id: 64 },
  { x: 520, y: 323, id: 65 },
  { x: 526, y: 301, id: 149 },
  { x: 565, y: 341, id: 66 },
  { x: 565, y: 290, id: 48 },
  { x: 595, y: 288, id: 48 },
  { x: 726, y: 337, id: 68 },
  { x: 675, y: 340, id: 81 },
  { x: 767, y: 334, id: 82 },
  { x: 666, y: 390, id: 80 },
  { x: 647, y: 404, id: 68 },
  { x: 513, y: 422, id: 78 },
  { x: 511, y: 397, id: 24 },
  { x: 572, y: 380, id: 150 },
  { x: 547, y: 375, id: 150 },
  { x: 468, y: 422, id: 79 },
  { x: 405, y: 432, id: 77 },
  { x: 375, y: 427, id: 76 },
  { x: 335, y: 390, id: 74 },
  { x: 282, y: 380, id: 73 },
  { x: 275, y: 432, id: 73 },
  { x: 380, y: 392, id: 75 },
  { x: 197, y: 430, id: 85 },
  { x: 74, y: 440, id: 84 },
  { x: 100, y: 395, id: 68 },
  { x: 56, y: 368, id: 69 },
  { x: 74, y: 364, id: 70 },
  { x: 36, y: 398, id: 68 },
  { x: 14, y: 418, id: 67 },
  { x: 25, y: 472, id: 105 },
  { x: 147, y: 465, id: 87 },
  { x: 261, y: 450, id: 88 },
  { x: 378, y: 480, id: 89 },
  { x: 918, y: 370, id: 91 },
  { x: 697, y: 482, id: 90 },
  { x: 512, y: 546, id: 142 },
  { x: 452, y: 554, id: 139 },
  { x: 353, y: 600, id: 136 },
  { x: 350, y: 630, id: 141 },
  { x: 405, y: 544, id: 137 },
  { x: 460, y: 490, id: 138 },
  { x: 207, y: 525, id: 132 },
  { x: 232, y: 499, id: 131 },
  { x: 209, y: 599, id: 134 },
  { x: 179, y: 579, id: 133 },
  // { x: 146, y: 341, id: 135 },
  { x: 119, y: 569, id: 130 },
  { x: 90, y: 520, id: 110 },
  { x: 32, y: 535, id: 129 },
  { x: 22, y: 570, id: 140 },
  { x: 160, y: 502, id: 106 },
  { x: 308, y: 506, id: 107 },
  { x: 276, y: 512, id: 108 },
  { x: 318, y: 530, id: 109 },
  { x: 347, y: 530, id: 146 },
];
