export const initialCoordinates1366x768 = [
  { x: 139, y: 57, id: 1 },
  { x: 170, y: 107, id: 2 },
  { x: 110, y: 128, id: 3 },
  { x: 170, y: 212, id: 22 },
  { x: 105, y: 234, id: 21 },
  { x: 270, y: 34, id: 4 },
  { x: 302, y: 120, id: 5 },
  { x: 266, y: 207, id: 23 },
  { x: 390, y: 84, id: 6 },
  { x: 460, y: 80, id: 7 },
  { x: 376, y: 153, id: 8 },
  { x: 334, y: 200, id: 25 },
  { x: 369, y: 284, id: 98 },
  { x: 485, y: 152, id: 9 },
  { x: 452, y: 220, id: 27 },
  { x: 526, y: 181, id: 28 },
  { x: 516, y: 108, id: 11 },
  { x: 766, y: 22, id: 10 },
  { x: 531, y: 238, id: 44 },
  { x: 632, y: 244, id: 45 },
  { x: 628, y: 211, id: 29 },
  { x: 771, y: 88, id: 13 },
  { x: 738, y: 91, id: 12 },
  { x: 833, y: 218, id: 30 },
  { x: 915, y: 142, id: 15 },
  { x: 905, y: 236, id: 31 },
  { x: 910, y: 49, id: 14 },
  { x: 964, y: 220, id: 32 },
  { x: 1021, y: 192, id: 33 },
  { x: 1122, y: 30, id: 17 },
  { x: 1047, y: 71, id: 18 },
  { x: 1145, y: 75, id: 19 },
  { x: 1079, y: 136, id: 34 },
  { x: 1019, y: 90, id: 16 },
  { x: 1138, y: 190, id: 35 },
  { x: 1092, y: 215, id: 52 },
  { x: 1141, y: 269, id: 53 },
  { x: 1093, y: 263, id: 50 },
  { x: 1191, y: 259, id: 52 },
  { x: 1258, y: 118, id: 20 },
  { x: 1275, y: 157, id: 37},
  { x: 1240, y: 150, id: 36 },
  { x: 1225, y: 257, id: 39 },
  { x: 1277, y: 230, id: 38 },
  { x: 1194, y: 318, id: 54 },
  { x: 1081, y: 307, id: 51 },
  { x: 1099, y: 362, id: 86 },
  { x: 1040, y: 332, id: 83 },
  { x: 960, y: 388, id: 82 },
  { x: 938, y: 457, id: 93 },
  { x: 1048, y: 440, id: 94 },
  { x: 961, y: 505, id: 94 },
  { x: 912, y: 500, id: 95 },
  { x: 903, y: 395, id: 68 },
  { x: 1148, y: 459, id: 97 },
  { x: 1117, y: 458, id: 92 },
  { x: 1127, y: 510, id: 99 },
  { x: 1234, y: 473, id: 103 },
  { x: 1277, y: 340, id: 104 },
  { x: 1248, y: 368, id: 101 },
  { x: 1240, y: 318, id: 102 },
  { x: 856, y: 408, id: 81 },
  { x: 833, y: 450, id: 80 },
  { x: 810, y: 464, id: 68 },
  { x: 850, y: 274, id: 49 },
  { x: 752, y: 337, id: 48 },
  { x: 727, y: 337, id: 48 },
  { x: 682, y: 373, id: 65 },
  { x: 673, y: 350, id: 149 },
  { x: 634, y: 354, id: 64 },
  { x: 728, y: 385, id: 66 },
  { x: 663, y: 447, id: 24 },
  { x: 664, y: 487, id: 78 },
  { x: 848, y: 514, id: 91 },
  { x: 609, y: 412, id: 152 },
  { x: 543, y: 384, id: 59 },
  { x: 336, y: 332, id: 111 },
  { x: 596, y: 367, id: 151 },
  { x: 552, y: 355, id: 62 },
  { x: 527, y: 360, id: 61 },
  { x: 487, y: 364, id: 56 },
  { x: 509, y: 378, id: 60 },
  { x: 457, y: 373, id: 58 },
  { x: 427, y: 371, id: 57 },
  { x: 437, y: 343, id: 55 },
  { x: 478, y: 429, id: 25 },
  { x: 447, y: 453, id: 74 },
  { x: 501, y: 498, id: 76 },
  { x: 545, y: 496, id: 77 },
  { x: 600, y: 486, id: 79 },
  { x: 576, y: 325, id: 44 },
  { x: 745, y: 271, id: 47 },
  { x: 617, y: 327, id: 46 },
  { x: 420, y: 410, id: 150 },
  { x: 726, y: 434, id: 150 },
  { x: 697, y: 425, id: 150 },
  { x: 373, y: 520, id: 88 },
  { x: 373, y: 494, id: 73 },
  { x: 392, y: 438, id: 73 },
  { x: 303, y: 275, id: 42 },
  { x: 410, y: 326, id: 43 },
  { x: 285, y: 496, id: 85 },
  { x: 506, y: 450, id: 75 },
  { x: 234, y: 374, id: 72 },
  { x: 167, y: 396, id: 71 },
  { x: 131, y: 417, id: 69 },
  { x: 153, y: 418, id: 70 },
  { x: 180, y: 454, id: 68 },
  { x: 175, y: 266, id: 41 },
  { x: 115, y: 367, id: 40 },
  { x: 108, y: 455, id: 68 },
  { x: 82, y: 484, id: 67 },
  { x: 135, y: 503, id: 84 },
  { x: 94, y: 546, id: 105 },
  { x: 112, y: 638, id: 129 },
  { x: 82, y: 658, id: 140 },
  { x: 167, y: 607, id: 110 },
  { x: 209, y: 670, id: 130 },
  { x: 279, y: 686, id: 133 },
  { x: 307, y: 698, id: 134 },
  { x: 338, y: 602, id: 131 },
  { x: 315, y: 608, id: 132 },
  { x: 389, y: 604, id: 107 },
  { x: 252, y: 565, id: 106 },
  { x: 242, y: 535, id: 87 },
  { x: 432, y: 622, id: 109 },
  { x: 467, y: 700, id: 136 },
  { x: 469, y: 742, id: 141 },
  { x: 463, y: 618, id: 146 },
  { x: 517, y: 558, id: 89 },
  { x: 590, y: 567, id: 138 },
  { x: 546, y: 627, id: 137 },
  { x: 597, y: 642, id: 139 },
  { x: 660, y: 631, id: 142 },
  { x: 750, y: 652, id: 143 },
  { x: 767, y: 543, id: 90 },
  { x: 811, y: 629, id: 112 },
  { x: 790, y: 745, id: 144 },
  { x: 901, y: 650, id: 114 },
  { x: 906, y: 547, id: 90 },
  { x: 937, y: 695, id: 115 },
  { x: 848, y: 672, id: 113 },
  { x: 875, y: 735, id: 116 },
  { x: 939, y: 748, id: 119 },
  { x: 1029, y: 562, id: 87 },
  // { x: 1025, y: 500, id: 96 },
  { x: 1053, y: 743, id: 120 },
  { x: 1081, y: 539, id: 100 },
  { x: 1100, y: 590, id: 118 },
  { x: 1041, y: 713, id: 121 },
  { x: 1150, y: 671, id: 123 },
  { x: 1130, y: 704, id: 124 },
  { x: 1230, y: 710, id: 125 },
  { x: 1173, y: 757, id: 126 },
  { x: 1267, y: 745, id: 127 },
  { x: 1270, y: 701, id: 128 },
  { x: 1224, y: 595, id: 117 },
];