export const initialCoordinates1920 = [
  { x: 189, y: 81, id: 1 },
  { x: 241, y: 152, id: 2 },
  { x: 154, y: 180, id: 3 },
  { x: 222, y: 299, id: 22 },
  { x: 423, y: 170, id: 5 },
  { x: 345, y: 49, id: 4 },
  { x: 380, y: 296, id: 23 },
  { x: 545, y: 125, id: 6 },
  { x: 650, y: 112, id: 7 },
  { x: 529, y: 213, id: 8 },
  { x: 676, y: 212, id: 9 },
  { x: 642, y: 310, id: 27 },
  { x: 545, y: 287, id: 26 },
  { x: 520, y: 402, id: 98 },
  { x: 474, y: 286, id: 25 },
  { x: 734, y: 261, id: 28 },
  { x: 739, y: 138, id: 11 },
  { x: 884, y: 297, id: 29 },
  { x: 1035, y: 38, id: 10 },
  { x: 1005, y: 178, id: 12 },
  { x: 1085, y: 129, id: 13 },
  { x: 1168, y: 312, id: 30 },
  { x: 1280, y: 70, id: 14 },
  { x: 1290, y: 197, id: 15 },
  { x: 1272, y: 334, id: 31 },
  { x: 1352, y: 303, id: 32 },
  { x: 1465, y: 101, id: 18 },
  { x: 1517, y: 192, id: 34 },
  { x: 1394, y: 125, id: 16 },
  { x: 1437, y: 275, id: 33 },
  { x: 1572, y: 43, id: 17 },
  { x: 1614, y: 107, id: 19 },
  { x: 1752, y: 220, id: 36 },
  { x: 1767, y: 154, id: 20 },
  { x: 1786, y: 220, id: 37 },
  { x: 1785, y: 320, id: 38 },
  { x: 1730, y: 360, id: 39 },
  { x: 1557, y: 322, id: 52 },
  { x: 1603, y: 278, id: 35 },
  { x: 1683, y: 389, id: 52 },
  { x: 1673, y: 446, id: 54 },
  { x: 1612, y: 389, id: 53 },
  { x: 1535, y: 398, id: 50 },
  { x: 1522, y: 430, id: 51 },
  { x: 1546, y: 510, id: 86 },
  { x: 1466, y: 468, id: 83 },
  { x: 1756, y: 453, id: 102 },
  { x: 1785, y: 491, id: 104 },
  { x: 1765, y: 525, id: 101 },
  { x: 1660, y: 623, id: 97 },
  { x: 1361, y: 705, id: 94 },
  { x: 1313, y: 645, id: 93 },
  { x: 1271, y: 557, id: 68 },
  { x: 1194, y: 578, id: 81 },
  { x: 1163, y: 633, id: 80 },
  { x: 1143, y: 653, id: 68 },
  { x: 1202, y: 393, id: 49 },
  { x: 1057, y: 479, id: 48 },
  { x: 1021, y: 474, id: 48 },
  { x: 1050, y: 370, id: 47 },
  { x: 960, y: 524, id: 65 },
  { x: 1024, y: 543, id: 66 },
  { x: 1021, y: 612, id: 150 },
  { x: 591, y: 576, id: 150 },
  { x: 980, y: 599, id: 150 },
  { x: 893, y: 502, id: 64 },
  { x: 883, y: 367, id: 45 },
  { x: 886, y: 477, id: 46 },
  { x: 933, y: 643, id: 24 },
  { x: 852, y: 588, id: 152 },
  { x: 846, y: 487, id: 151 },
  { x: 472, y: 467, id: 111 },
  { x: 763, y: 546, id: 59 },
  { x: 738, y: 506, id: 61 },
  { x: 775, y: 506, id: 62 },
  { x: 799, y: 462, id: 44 },
  { x: 689, y: 516, id: 56 },
  { x: 714, y: 533, id: 60 },
  { x: 686, y: 610, id: 25 },
  { x: 637, y: 632, id: 74 },
  { x: 620, y: 485, id: 55 },
  { x: 605, y: 534, id: 57 },
  { x: 645, y: 533, id: 58 },
  { x: 579, y: 463, id: 43 },
  { x: 425, y: 391, id: 42 },
  { x: 327, y: 517, id: 72 },
  { x: 553, y: 617, id: 73 },
  { x: 255, y: 639, id: 68 },
  { x: 228, y: 560, id: 71 },
  { x: 192, y: 601, id: 69 },
  { x: 214, y: 592, id: 70 },
  { x: 143, y: 337, id: 21 },
  { x: 237, y: 437, id: 41 },
  { x: 160, y: 505, id: 40 },
  { x: 152, y: 645, id: 68 },
  { x: 115, y: 678, id: 67 },
  { x: 200, y: 710, id: 84 },
  { x: 133, y: 776, id: 105 },
  { x: 400, y: 700, id: 85 },
  { x: 335, y: 762, id: 87 },
  { x: 350, y: 792, id: 106 },
  { x: 710, y: 633, id: 75 },
  { x: 523, y: 695, id: 73 },
  { x: 502, y: 737, id: 88 },
  { x: 704, y: 697, id: 76 },
  { x: 715, y: 783, id: 89 },
  { x: 756, y: 704, id: 77 },
  { x: 853, y: 685, id: 79 },
  { x: 933, y: 689, id: 78 },
  { x: 1083, y: 764, id: 90 },
  { x: 1227, y: 747, id: 91 },
  { x: 1298, y: 704, id: 95 },
  { x: 1224, y: 780, id: 90 },
  { x: 1459, y: 633, id: 94 },
  { x: 1446, y: 787, id: 87 },
  { x: 1537, y: 759, id: 100 },
  { x: 1593, y: 730, id: 99 },
  { x: 1576, y: 653, id: 92 },
  // { x: 1447, y: 711, id: 96 },
  { x: 1730, y: 656, id: 103 },
  { x: 1722, y: 838, id: 117 },
  { x: 1753, y: 900, id: 117 },
  { x: 1546, y: 826, id: 118 },
  { x: 1602, y: 964, id: 123 },
  { x: 1719, y: 995, id: 125 },
  { x: 1604, y: 994, id: 124 },
  { x: 1283, y: 915, id: 114 },
  { x: 1315, y: 980, id: 115 },
  { x: 1137, y: 890, id: 112 },
  { x: 1195, y: 947, id: 113 },
  { x: 1056, y: 920, id: 143 },
  { x: 847, y: 901, id: 139 },
  { x: 830, y: 798, id: 138 },
  { x: 767, y: 884, id: 137 },
  { x: 927, y: 887, id: 142 },
  { x: 662, y: 984, id: 136 },
  { x: 658, y: 874, id: 146 },
  { x: 573, y: 883, id: 107 },
  { x: 614, y: 862, id: 109 },
  { x: 543, y: 839, id: 108 },
  { x: 444, y: 855, id: 132 },
  { x: 473, y: 812, id: 131 },
  { x: 442, y: 988, id: 134 },
  { x: 387, y: 952, id: 133 },
  { x: 296, y: 941, id: 130 },
  { x: 236, y: 860, id: 110 },
  { x: 151, y: 874, id: 129 },
  { x: 121, y: 924, id: 140 },
  // { x: 437, y: 1027, id: 135 },
  { x: 672, y: 1029, id: 141 },
  { x: 1107, y: 1040, id: 144 },
  { x: 1315, y: 1049, id: 119 },
  { x: 1212, y: 1040, id: 116 },
  { x: 1465, y: 1001, id: 121 },
  { x: 1478, y: 1047, id: 120 },
  { x: 1662, y: 1050, id: 126 },
  { x: 1785, y: 1050, id: 127 },
  { x: 1785, y: 985, id: 128 },
  { x: 946, y: 495, id: 149 },
  { x: 1343, y: 550, id: 82 },
];
