export const initialCoordinates1280 = [
    { x: 117, y: 53, id: 1 },
    { x: 158, y: 96, id: 2 },
    { x: 102, y: 119, id: 3 },
    { x: 224, y: 32, id: 4 },
    { x: 281, y: 112, id: 5 },
    { x: 366, y: 74, id: 6 },
    { x: 431, y: 77, id: 7 },
    { x: 354, y: 143, id: 8 },
    { x: 450, y: 141, id: 9},
    { x: 488, y: 87, id: 11 },
    { x: 484, y: 173, id: 28 },
    { x: 633, y: 32, id: 10 },
    { x: 693, y: 81, id: 12 },
    { x: 725, y: 84, id: 13 },
    { x: 855, y: 130, id: 15 },
    { x: 849, y: 46, id: 14 },
    { x: 978, y: 67, id: 18 },
    { x: 948, y: 82, id: 16 },
    { x: 1052, y: 28, id: 17 },
    { x: 1074, y: 72, id: 19 },
    { x: 1010, y: 126, id: 34 },
    { x: 1061, y: 181, id: 35 },
    { x: 1177, y: 99, id: 20 },
    { x: 1201, y: 154, id: 37 },
    { x: 1161, y: 152, id: 36 },
    { x: 1201, y: 218, id: 38 },
    { x: 1155, y: 221, id: 39 },
    { x: 1115, y: 250, id: 52 },
    { x: 1033, y: 209, id: 52 },
    { x: 1022, y: 270, id: 50 },
    { x: 1067, y: 257, id: 53 },
    { x: 1115, y: 296, id: 54 },
    { x: 1007, y: 288, id: 51 },
    { x: 956, y: 180, id: 33 },
    { x: 897, y: 201, id: 32 },
    { x: 845, y: 222, id: 31 },
    { x: 778, y: 193, id: 30 },
    { x: 699, y: 242, id: 47 },
    { x: 583, y: 196, id: 29 },
    { x: 586, y: 239, id: 45 },
    { x: 671, y: 314, id: 48 },
    { x: 708, y: 315, id: 48 },
    { x: 505, y: 223, id: 44 },
    { x: 534, y: 302, id: 44 },
    { x: 381, y: 305, id: 43 },
    { x: 363, y: 191, id: 26 },
    { x: 345, y: 267, id: 98 },
    { x: 423, y: 206, id: 27 },
    { x: 323, y: 189, id: 25 },
    { x: 279, y: 253, id: 42 },
    { x: 247, y: 194, id: 23 },
    { x: 149, y: 196, id: 22 },
    { x: 92, y: 221, id: 21 },
    { x: 160, y: 290, id: 41 },
    { x: 106, y: 353, id: 40 },
    { x: 153, y: 371, id: 71 },
    { x: 220, y: 349, id: 72 },
    { x: 125, y: 393, id: 69 },
    { x: 142, y: 392, id: 70 },
    { x: 102, y: 430, id: 68 },
    { x: 76, y: 454, id: 67 },
    { x: 167, y: 428, id: 68 },
    { x: 473, y: 420, id: 75 },
    { x: 400, y: 348, id: 57 },
    { x: 367, y: 412, id: 73 },
    { x: 424, y: 421, id: 74 },
    { x: 454, y: 405, id: 25 },
    { x: 459, y: 332, id: 56 },
    { x: 424, y: 350, id: 58 },
    { x: 409, y: 315, id: 55 },
    { x: 477, y: 354, id: 60 },
    { x: 491, y: 341, id: 61 },
    { x: 504, y: 365, id: 59 },
    { x: 511, y: 338, id: 62 },
    { x: 315, y: 312, id: 111 },
    { x: 561, y: 324, id: 151 },
    { x: 587, y: 315, id: 46 },
    { x: 629, y: 348, id: 65 },
    { x: 585, y: 391, id: 152 },
    { x: 670, y: 368, id: 66 },
    { x: 680, y: 408, id: 150 },
    { x: 393, y: 383, id: 150 },
    { x: 653, y: 399, id: 66 },
    { x: 618, y: 420, id: 24 },
    { x: 421, y: 420, id: 74 },
    { x: 797, y: 383, id: 81 },
    { x: 795, y: 263, id: 49 },
    { x: 771, y: 413, id: 80 },
    { x: 763, y: 437, id: 68 },
    { x: 890, y: 363, id: 82 },
    { x: 846, y: 368, id: 68 },
    { x: 877, y: 436, id: 93 },
    { x: 1021, y: 350, id: 86 },
    { x: 976, y: 311, id: 83 },
    { x: 971, y: 423, id: 94 },
    { x: 1049, y: 433, id: 92 },
    { x: 1059, y: 413, id: 91 },
    { x: 1195, y: 320, id: 104 },
    { x: 1170, y: 343, id: 101 },
    { x: 1170, y: 293, id: 102 },
    { x: 1200, y: 423, id: 103 },
    { x: 1108, y: 434, id: 97 },
    { x: 1058, y: 483, id: 99 },
    { x: 1142, y: 553, id: 117 },
    { x: 1034, y: 547, id: 118 },
    { x: 966, y: 521, id: 87 },
    // { x: 960, y: 470, id: 96 },
    { x: 1020, y: 510, id: 100 },
    { x: 807, y: 522, id: 90 },
    { x: 857, y: 467, id: 95 },
    { x: 703, y: 613, id: 143 },
    { x: 753, y: 592, id: 121 },
    { x: 613, y: 460, id: 78 },
    { x: 501, y: 465, id: 77 },
    { x: 551, y: 460, id: 79 },
    { x: 467, y: 463, id: 76 },
    { x: 482, y: 521, id: 89 },
    { x: 343, y: 465, id: 73 },
    { x: 329, y: 491, id: 88 },
    { x: 428, y: 577, id: 146 },
    { x: 503, y: 586, id: 137 },
    { x: 548, y: 527, id: 138 },
    { x: 619, y: 574, id: 142 },
    { x: 358, y: 554, id: 108 },
    { x: 373, y: 588, id: 107 },
    { x: 401, y: 573, id: 109 },
    { x: 295, y: 569, id: 132 },
    { x: 232, y: 541, id: 105 },
    { x: 317, y: 544, id: 131 },
    { x: 157, y: 572, id: 110 },
    { x: 216, y: 505, id: 87 },
    { x: 266, y: 470, id: 85 },
    { x: 128, y: 474, id: 84 },
    { x: 84, y: 527, id: 105 },
    { x: 97, y: 580, id: 129 },
    { x: 87, y: 620, id: 140 },
    { x: 191, y: 620, id: 130 },
    { x: 287, y: 653, id: 134 },
    { x: 254, y: 642, id: 133 },
    // { x: 286, y: 681, id: 135 },
    { x: 442, y: 680, id: 141 },
    { x: 437, y: 651, id: 136 },
    { x: 556, y: 599, id: 139 },
    { x: 733, y: 691, id: 144 },
    { x: 795, y: 630, id: 113 },
    { x: 822, y: 689, id: 116 },
    { x: 874, y: 662, id: 115 },
    { x: 855, y: 602, id: 114 },
    { x: 867, y: 704, id: 119 },
    { x: 973, y: 670, id: 121 },
    { x: 1060, y: 636, id: 123 },
    { x: 1060, y: 661, id: 124 },
    { x: 1138, y: 663, id: 125 },
    { x: 1180, y: 705, id: 127 },
    { x: 1195, y: 658, id: 128 },
    { x: 1166, y: 605, id: 117 },
    { x: 631, y: 330, id: 149 },
  ];
  