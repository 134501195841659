import './App.scss';
import './styles/fonts.css';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

export const App = () => {
  const [showMessage, setShowMessage] = useState(false);


  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isAllowedBrowser = /Chrome|CriOS|Mobile Safari|Safari|Edg|Firefox|OPR/i.test(navigator.userAgent);

    if (isMobile && !isAllowedBrowser) {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
      }
    }
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1200) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    };

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  return (
    <div className='section'>
      {showMessage && (
        <div className='warning-message'>
          Please note, this website is designed for mobile devices.
          <br/>We kindly ask you to access it on your phone.
        </div>
      )}
      {!showMessage && <Outlet />}
    </div>
  );
};
