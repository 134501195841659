export const initialCoordinates360x780 = [
  { x: 56, y: 45, id: 1 },
  { x: 105, y: 110, id: 2 },
  { x: 46, y: 125, id: 3 },
  { x: 160, y: 38, id: 4 },
  { x: 200, y: 116, id: 5 },
  { x: 313, y: 62, id: 6 },
  { x: 395, y: 67, id: 7 },
  { x: 317, y: 150, id: 8 },
  { x: 409, y: 140, id: 9 },
  { x: 635, y: 28, id: 10 },
  { x: 603, y: 102, id: 11 },
  { x: 665, y: 120, id: 12 },
  { x: 734, y: 117, id: 13 },
  { x: 856, y: 49, id: 14 },
  { x: 850, y: 126, id: 15 },
  { x: 922, y: 92, id: 16 },
  { x: 909, y: 211, id: 32 },
  { x: 853, y: 240, id: 31 },
  { x: 970, y: 192, id: 33 },
  { x: 1066, y: 30, id: 17 },
  { x: 997, y: 73, id: 18 },
  { x: 1097, y: 77, id: 19 },
  { x: 1084, y: 195, id: 35 },
  { x: 1030, y: 138, id: 34 },
  { x: 1052, y: 227, id: 52 },
  { x: 1132, y: 260, id: 52 },
  { x: 1090, y: 275, id: 53 },
  { x: 1040, y: 275, id: 50 },
  { x: 1020, y: 305, id: 51 },
  { x: 1202, y: 108, id: 20 },
  { x: 1222, y: 158, id: 37 },
  { x: 1192, y: 155, id: 36 },
  { x: 1220, y: 230, id: 38 },
  { x: 1183, y: 260, id: 39 },
  { x: 1140, y: 323, id: 54 },
  { x: 1190, y: 320, id: 102 },
  { x: 1044, y: 361, id: 86 },
  { x: 990, y: 341, id: 83 },
  { x: 1220, y: 345, id: 104 },
  { x: 1185, y: 365, id: 101 },
  { x: 1185, y: 465, id: 103 },
  { x: 1125, y: 455, id: 97 },
  { x: 1058, y: 450, id: 92 },
  { x: 1075, y: 493, id: 99 },
  { x: 1200, y: 605, id: 117 },
  { x: 1050, y: 605, id: 118 },
  { x: 974, y: 563, id: 87 },
  { x: 1037, y: 553, id: 100 },
  // { x: 977, y: 509, id: 96 },
  { x: 1090, y: 685, id: 123 },
  { x: 1168, y: 720, id: 125 },
  { x: 1082, y: 710, id: 124 },
  { x: 1207, y: 714, id: 128 },
  { x: 1207, y: 754, id: 127 },
  { x: 1097, y: 750, id: 126 },
  { x: 992, y: 724, id: 121 },
  { x: 878, y: 752, id: 119 },
  { x: 958, y: 754, id: 120 },
  { x: 885, y: 705, id: 115 },
  { x: 860, y: 645, id: 114 },
  { x: 800, y: 685, id: 113 },
  { x: 823, y: 745, id: 116 },
  { x: 875, y: 470, id: 93 },
  { x: 865, y: 503, id: 95 },
  { x: 790, y: 523, id: 122 },
  { x: 915, y: 515, id: 94 },
  { x: 985, y: 450, id: 94 },
  { x: 750, y: 636, id: 112 },
  { x: 730, y: 744, id: 144 },
  { x: 693, y: 664, id: 143 },
  { x: 43, y: 240, id: 21 },
  { x: 100, y: 212, id: 22 },
  { x: 201, y: 210, id: 23 },
  { x: 273, y: 200, id: 25 },
  { x: 390, y: 223, id: 27 },
  { x: 458, y: 193, id: 28 },
  { x: 565, y: 201, id: 29 },
  { x: 575, y: 261, id: 45 },
  { x: 555, y: 331, id: 46 },
  { x: 690, y: 273, id: 47 },
  { x: 776, y: 223, id: 30 },
  { x: 796, y: 279, id: 49 },
  { x: 471, y: 240, id: 44 },
  { x: 325, y: 210, id: 26 },
  { x: 232, y: 278, id: 42 },
  { x: 49, y: 365, id: 40 },
  { x: 102, y: 302, id: 41 },
  { x: 160, y: 322, id: 72 },
  { x: 170, y: 392, id: 145 },
  { x: 98, y: 405, id: 71 },
  { x: 335, y: 292, id: 98 },
  { x: 343, y: 330, id: 43 },
  { x: 380, y: 343, id: 55 },
  { x: 420, y: 358, id: 56 },
  { x: 365, y: 378, id: 57 },
  { x: 392, y: 380, id: 58 },
  { x: 437, y: 374, id: 60 },
  { x: 457, y: 354, id: 61 },
  { x: 477, y: 384, id: 59 },
  { x: 477, y: 354, id: 62 },
  { x: 503, y: 350, id: 63 },
  { x: 537, y: 404, id: 152 },
  { x: 275, y: 340, id: 111 },
  { x: 537, y: 354, id: 151 },
  { x: 577, y: 364, id: 64 },
  { x: 610, y: 369, id: 65 },
  { x: 607, y: 339, id: 149 },
  { x: 660, y: 399, id: 66 },
  { x: 666, y: 339, id: 48 },
  { x: 700, y: 339, id: 48 },
  { x: 850, y: 397, id: 68 },
  { x: 790, y: 413, id: 81 },
  { x: 895, y: 393, id: 82 },
  { x: 778, y: 453, id: 80 },
  { x: 758, y: 473, id: 68 },
  { x: 603, y: 493, id: 78 },
  { x: 607, y: 463, id: 24 },
  { x: 671, y: 443, id: 150 },
  { x: 641, y: 433, id: 150 },
  { x: 547, y: 496, id: 79 },
  { x: 475, y: 503, id: 77 },
  { x: 440, y: 508, id: 76 },
  { x: 390, y: 455, id: 74 },
  { x: 333, y: 450, id: 73 },
  { x: 320, y: 505, id: 73 },
  { x: 443, y: 453, id: 75 },
  { x: 224, y: 502, id: 85 },
  { x: 70, y: 510, id: 84 },
  { x: 43, y: 467, id: 68 },
  { x: 59, y: 417, id: 69 },
  { x: 108, y: 465, id: 68 },
  { x: 78, y: 415, id: 70 },
  { x: 16, y: 488, id: 67 },
  { x: 30, y: 558, id: 105 },
  { x: 160, y: 545, id: 87 },
  { x: 301, y: 530, id: 88 },
  { x: 458, y: 560, id: 89 },
  { x: 1093, y: 416, id: 91 },
  { x: 797, y: 562, id: 90 },
  { x: 582, y: 630, id: 142 },
  { x: 532, y: 650, id: 139 },
  { x: 407, y: 704, id: 136 },
  { x: 412, y: 754, id: 141 },
  { x: 478, y: 634, id: 137 },
  { x: 535, y: 570, id: 138 },
  { x: 255, y: 620, id: 132 },
  { x: 215, y: 690, id: 133 },
  { x: 239, y: 699, id: 134 },
  { x: 275, y: 599, id: 131 },
  // { x: 146, y: 341, id: 135 },
  { x: 149, y: 679, id: 130 },
  { x: 104, y: 610, id: 110 },
  { x: 35, y: 632, id: 129 },
  { x: 22, y: 660, id: 140 },
  { x: 182, y: 582, id: 106 },
  { x: 358, y: 596, id: 107 },
  { x: 321, y: 599, id: 108 },
  { x: 371, y: 620, id: 109 },
  { x: 420, y: 620, id: 146 }
]
