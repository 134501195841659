export const initialCoordinates414x736  = [
  { x: 53, y: 40, id: 1 },
  { x: 92, y: 92, id: 2 },
  { x: 41, y: 125, id: 3 },
  { x: 180, y: 32, id: 4 },
  { x: 225, y: 106, id: 5 },
  { x: 310, y: 79, id: 6 },
  { x: 376, y: 67, id: 7 },
  { x: 297, y: 140, id: 8 },
  { x: 363, y: 125, id: 9 },
  { x: 565, y: 28, id: 10 },
  { x: 572, y: 95, id: 11 },
  { x: 619, y: 120, id: 12 },
  { x: 680, y: 107, id: 13 },
  { x: 810, y: 49, id: 14 },
  { x: 797, y: 110, id: 15 },
  { x: 872, y: 84, id: 16 },
  { x: 856, y: 211, id: 32 },
  { x: 800, y: 223, id: 31 },
  { x: 915, y: 187, id: 33 },
  { x: 1011, y: 29, id: 17 },
  { x: 938, y: 69, id: 18 },
  { x: 1034, y: 73, id: 19 },
  { x: 968, y: 127, id: 34 },
  { x: 1022, y: 178, id: 35 },
  { x: 985, y: 212, id: 52 },
  { x: 1074, y: 250, id: 52 },
  { x: 1032, y: 255, id: 53 },
  { x: 984, y: 258, id: 50 },
  { x: 954, y: 278, id: 51 },
  { x: 1135, y: 105, id: 20 },
  { x: 1150, y: 145, id: 37 },
  { x: 1125, y: 159, id: 36 },
  { x: 1149, y: 220, id: 38 },
  { x: 1120, y: 250, id: 39 },
  { x: 1078, y: 305, id: 54 },
  { x: 1124, y: 300, id: 102 },
  { x: 994, y: 341, id: 86 },
  { x: 935, y: 320, id: 83 },
  { x: 1146, y: 315, id: 104 },
  { x: 1130, y: 350, id: 101 },
  { x: 1120, y: 426, id: 103 },
  { x: 1060, y: 436, id: 97 },
  { x: 1010, y: 446, id: 92 },
  { x: 1013, y: 466, id: 99 },
  { x: 1105, y: 558, id: 117 },
  { x: 990, y: 570, id: 118 },
  { x: 920, y: 531, id: 87 },
  { x: 978, y: 510, id: 100 },
  // { x: 917, y: 480, id: 96 },
  { x: 1027, y: 649, id: 123 },
  { x: 1100, y: 679, id: 125 },
  { x: 1013, y: 670, id: 124 },
  { x: 1145, y: 665, id: 128 },
  { x: 1150, y: 700, id: 127 },
  { x: 1045, y: 706, id: 126 },
  { x: 935, y: 682, id: 121 },
  { x: 829, y: 703, id: 119 },
  { x: 899, y: 703, id: 120 },
  { x: 830, y: 669, id: 115 },
  { x: 803, y: 605, id: 114 },
  { x: 765, y: 645, id: 113 },
  { x: 777, y: 697, id: 116 },
  { x: 825, y: 440, id: 93 },
  { x: 817, y: 475, id: 95 },
  { x: 737, y: 485, id: 122 },
  { x: 858, y: 485, id: 94 },
  { x: 910, y: 420, id: 94 },
  { x: 705, y: 600, id: 112 },
  { x: 692, y: 705, id: 144 },
  { x: 650, y: 625, id: 143 },
  { x: 43, y: 220, id: 21 },
  { x: 95, y: 197, id: 22 },
  { x: 188, y: 198, id: 23 },
  { x: 260, y: 193, id: 25 },
  { x: 370, y: 205, id: 27 },
  { x: 434, y: 175, id: 28 },
  { x: 532, y: 203, id: 29 },
  { x: 535, y: 243, id: 45 },
  { x: 533, y: 320, id: 46 },
  { x: 650, y: 253, id: 47 },
  { x: 732, y: 220, id: 30 },
  { x: 750, y: 265, id: 49 },
  { x: 471, y: 225, id: 44 },
  { x: 307, y: 195, id: 26 },
  { x: 222, y: 258, id: 42 },
  { x: 45, y: 342, id: 40 },
  { x: 92, y: 292, id: 41 },
  { x: 148, y: 302, id: 72 },
  { x: 158, y: 372, id: 145 },
  { x: 94, y: 378, id: 71 },
  { x: 315, y: 272, id: 98 },
  { x: 315, y: 308, id: 43 },
  { x: 356, y: 323, id: 55 },
  { x: 393, y: 338, id: 56 },
  { x: 335, y: 345, id: 57 },
  { x: 358, y: 355, id: 58 },
  { x: 422, y: 360, id: 60 },
  { x: 428, y: 324, id: 61 },
  { x: 460, y: 360, id: 59 },
  { x: 455, y: 332, id: 62 },
  { x: 475, y: 322, id: 63 },
  { x: 505, y: 382, id: 152 },
  { x: 255, y: 315, id: 111 },
  { x: 502, y: 340, id: 151 },
  { x: 540, y: 340, id: 64 },
  { x: 570, y: 353, id: 65 },
  { x: 581, y: 336, id: 149 },
  { x: 622, y: 371, id: 66 },
  { x: 625, y: 320, id: 48 },
  { x: 655, y: 328, id: 48 },
  { x: 800, y: 367, id: 68 },
  { x: 745, y: 380, id: 81 },
  { x: 847, y: 374, id: 82 },
  { x: 736, y: 430, id: 80 },
  { x: 717, y: 448, id: 68 },
  { x: 567, y: 472, id: 78 },
  { x: 570, y: 427, id: 24 },
  { x: 632, y: 420, id: 150 },
  { x: 604, y: 410, id: 150 },
  { x: 518, y: 467, id: 79 },
  { x: 447, y: 476, id: 77 },
  { x: 415, y: 467, id: 76 },
  { x: 367, y: 428, id: 74 },
  { x: 312, y: 420, id: 73 },
  { x: 300, y: 472, id: 73 },
  { x: 420, y: 432, id: 75 },
  { x: 217, y: 473, id: 85 },
  { x: 74, y: 480, id: 84 },
  { x: 110, y: 435, id: 68 },
  { x: 60, y: 398, id: 69 },
  { x: 80, y: 402, id: 70 },
  { x: 39, y: 438, id: 68 },
  { x: 14, y: 458, id: 67 },
  { x: 25, y: 522, id: 105 },
  { x: 167, y: 505, id: 87 },
  { x: 281, y: 500, id: 88 },
  { x: 428, y: 530, id: 89 },
  { x: 1018, y: 400, id: 91 },
  { x: 737, y: 522, id: 90 },
  { x: 572, y: 596, id: 142 },
  { x: 502, y: 614, id: 139 },
  { x: 387, y: 660, id: 136 },
  { x: 386, y: 695, id: 141 },
  { x: 445, y: 594, id: 137 },
  { x: 504, y: 540, id: 138 },
  { x: 237, y: 575, id: 132 },
  { x: 252, y: 539, id: 131 },
  { x: 229, y: 669, id: 134 },
  { x: 209, y: 649, id: 133 },
  // { x: 146, y: 341, id: 135 },
  { x: 129, y: 629, id: 130 },
  { x: 97, y: 580, id: 110 },
  { x: 32, y: 585, id: 129 },
  { x: 22, y: 630, id: 140 },
  { x: 175, y: 552, id: 106 },
  { x: 328, y: 546, id: 107 },
  { x: 296, y: 562, id: 108 },
  { x: 338, y: 580, id: 109 },
  { x: 380, y: 580, id: 146},
];
