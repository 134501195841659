export const initialCoordinates1024 = [
  { x: 78, y: 62, id: 1 },
  { x: 135, y: 140, id: 2 },
  { x: 56, y: 165, id: 3 },
  { x: 240, y: 54, id: 4 },
  { x: 310, y: 150, id: 5 },
  { x: 428, y: 112, id: 6 },
  { x: 525, y: 107, id: 7 },
  { x: 413, y: 195, id: 8 },
  { x: 550, y: 190, id: 9 },
  { x: 755, y: 28, id: 10 },
  { x: 807, y: 132, id: 11 },
  { x: 895, y: 115, id: 12 },
  { x: 944, y: 153, id: 13 },
  { x: 1125, y: 65, id: 14 },
  { x: 1130, y: 186, id: 15 },
  { x: 1232, y: 117, id: 16 },
  { x: 1190, y: 285, id: 32 },
  { x: 1115, y: 316, id: 31 },
  { x: 1272, y: 257, id: 33 },
  { x: 1400, y: 39, id: 17 },
  { x: 1302, y: 96, id: 18 },
  { x: 1437, y: 100, id: 19 },
  { x: 1354, y: 180, id: 34 },
  { x: 1420, y: 248, id: 35 },
  { x: 1362, y: 283, id: 52 },
  { x: 1492, y: 350, id: 52 },
  { x: 1435, y: 345, id: 53 },
  { x: 1360, y: 348, id: 50 },
  { x: 1340, y: 398, id: 51 },
  { x: 1568, y: 140, id: 20 },
  { x: 1605, y: 198, id: 37 },
  { x: 1563, y: 198, id: 36 },
  { x: 1600, y: 305, id: 38 },
  { x: 1550, y: 345, id: 39 },
  { x: 1492, y: 423, id: 54 },
  { x: 1570, y: 430, id: 102 },
  { x: 1364, y: 471, id: 86 },
  { x: 1300, y: 441, id: 83 },
  { x: 1612, y: 435, id: 104 },
  { x: 1565, y: 490, id: 101 },
  { x: 1550, y: 595, id: 103 },
  { x: 1475, y: 592, id: 97 },
  { x: 1397, y: 593, id: 92 },
  { x: 1445, y: 680, id: 99 },
  { x: 1570, y: 785, id: 117 },
  { x: 1370, y: 795, id: 118 },
  { x: 1300, y: 745, id: 87 },
  { x: 1360, y: 750, id: 100 },
  // { x: 1290, y: 660, id: 96 },
  { x: 1440, y: 890, id: 123 },
  { x: 1550, y: 925, id: 125 },
  { x: 1442, y: 930, id: 124 },
  { x: 1610, y: 934, id: 128 },
  { x: 1585, y: 984, id: 127 },
  { x: 1485, y: 994, id: 126 },
  { x: 1298, y: 952, id: 121 },
  { x: 1155, y: 989, id: 119 },
  { x: 1255, y: 959, id: 120 },
  { x: 1155, y: 929, id: 115 },
  { x: 1119, y: 865, id: 114 },
  { x: 1042, y: 895, id: 113 },
  { x: 1078, y: 975, id: 116 },
  { x: 1140, y: 600, id: 93 },
  { x: 1155, y: 658, id: 95 },
  { x: 1055, y: 688, id: 122 },
  { x: 1200, y: 667, id: 94 },
  { x: 1290, y: 590, id: 94 },
  { x: 992, y: 833, id: 112 },
  { x: 982, y: 990, id: 144 },
  { x: 912, y: 870, id: 143 },
  { x: 53, y: 310, id: 21 },
  { x: 127, y: 282, id: 22 },
  { x: 262, y: 270, id: 23 },
  { x: 360, y: 265, id: 25 },
  { x: 515, y: 293, id: 27 },
  { x: 650, y: 243, id: 28 },
  { x: 740, y: 251, id: 29 },
  { x: 740, y: 321, id: 45 },
  { x: 735, y: 431, id: 46 },
  { x: 895, y: 333, id: 47 },
  { x: 1003, y: 263, id: 30 },
  { x: 1033, y: 359, id: 49 },
  { x: 610, y: 305, id: 44 },
  { x: 418, y: 260, id: 26 },
  { x: 302, y: 348, id: 42 },
  { x: 55, y: 460, id: 40 },
  { x: 120, y: 362, id: 41 },
  { x: 212, y: 422, id: 72 },
  { x: 222, y: 522, id: 145 },
  { x: 118, y: 475, id: 71 },
  { x: 400, y: 362, id: 98 },
  { x: 445, y: 420, id: 43 },
  { x: 480, y: 433, id: 55 },
  { x: 550, y: 468, id: 56 },
  { x: 470, y: 478, id: 57 },
  { x: 505, y: 488, id: 58 },
  { x: 575, y: 492, id: 60 },
  { x: 602, y: 452, id: 61 },
  { x: 632, y: 492, id: 59 },
  { x: 632, y: 462, id: 62 },
  { x: 662, y: 462, id: 63 },
  { x: 712, y: 522, id: 152 },
  { x: 350, y: 430, id: 111 },
  { x: 690, y: 454, id: 151 },
  { x: 747, y: 464, id: 64 },
  { x: 800, y: 489, id: 65 },
  { x: 800, y: 456, id: 149 },
  { x: 852, y: 502, id: 66 },
  { x: 863, y: 440, id: 48 },
  { x: 906, y: 437, id: 48 },
  { x: 1105, y: 507, id: 68 },
  { x: 1028, y: 523, id: 81 },
  { x: 1165, y: 510, id: 82 },
  { x: 1008, y: 585, id: 80 },
  { x: 988, y: 609, id: 68 },
  { x: 778, y: 630, id: 78 },
  { x: 788, y: 592, id: 24 },
  { x: 828, y: 559, id: 150 },
  { x: 868, y: 569, id: 150 },
  { x: 710, y: 639, id: 79 },
  { x: 617, y: 650, id: 77 },
  { x: 560, y: 638, id: 76 },
  { x: 504, y: 589, id: 74 },
  { x: 425, y: 570, id: 73 },
  { x: 400, y: 650, id: 73 },
  { x: 560, y: 585, id: 75 },
  { x: 284, y: 650, id: 85 },
  { x: 100, y: 665, id: 84 },
  { x: 45, y: 595, id: 68 },
  { x: 79, y: 547, id: 69 },
  { x: 138, y: 595, id: 68 },
  { x: 105, y: 548, id: 70 },
  { x: 12, y: 622, id: 67 },
  { x: 30, y: 708, id: 105 },
  { x: 210, y: 695, id: 87 },
  { x: 391, y: 670, id: 88 },
  { x: 578, y: 710, id: 89 },
  { x: 1410, y: 540, id: 91 },
  { x: 1047, y: 732, id: 90 },
  { x: 783, y: 775, id: 142 },
  { x: 683, y: 835, id: 139 },
  { x: 527, y: 914, id: 136 },
  { x: 530, y: 959, id: 141 },
  { x: 620, y: 833, id: 137 },
  { x: 690, y: 743, id: 138 },
  { x: 320, y: 799, id: 132 },
  { x: 275, y: 890, id: 133 },
  { x: 329, y: 920, id: 134 },
  { x: 344, y: 739, id: 131 },
  // { x: 146, y: 341, id: 135 },
  { x: 175, y: 869, id: 130 },
  { x: 125, y: 795, id: 110 },
  { x: 35, y: 822, id: 129 },
  { x: 48, y: 960, id: 140 },
  { x: 232, y: 742, id: 106 },
  { x: 453, y: 766, id: 107 },
  { x: 408, y: 789, id: 108 },
  { x: 471, y: 800, id: 109 },
  { x: 520, y: 810, id: 146 }
];
