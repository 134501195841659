export const initialCoordinates414x896 = [
  { x: 70, y: 48, id: 1 },
  { x: 115, y: 120, id: 2 },
  { x: 56, y: 145, id: 3 },
  { x: 210, y: 44, id: 4 },
  { x: 275, y: 130, id: 5 },
  { x: 382, y: 92, id: 6 },
  { x: 465, y: 87, id: 7 },
  { x: 367, y: 175, id: 8 },
  { x: 480, y: 160, id: 9 },
  { x: 685, y: 28, id: 10 },
  { x: 697, y: 122, id: 11 },
  { x: 755, y: 140, id: 12 },
  { x: 834, y: 133, id: 13 },
  { x: 990, y: 58, id: 14 },
  { x: 970, y: 126, id: 15 },
  { x: 1072, y: 102, id: 16 },
  { x: 1045, y: 255, id: 32 },
  { x: 980, y: 276, id: 31 },
  { x: 1118, y: 227, id: 33 },
  { x: 1226, y: 35, id: 17 },
  { x: 1147, y: 85, id: 18 },
  { x: 1259, y: 89, id: 19 },
  { x: 1184, y: 160, id: 34 },
  { x: 1246, y: 218, id: 35 },
  { x: 1212, y: 263, id: 52 },
  { x: 1302, y: 300, id: 52 },
  { x: 1260, y: 315, id: 53 },
  { x: 1200, y: 310, id: 50 },
  { x: 1180, y: 350, id: 51 },
  { x: 1378, y: 130, id: 20 },
  { x: 1410, y: 178, id: 37 },
  { x: 1373, y: 175, id: 36 },
  { x: 1400, y: 260, id: 38 },
  { x: 1363, y: 300, id: 39 },
  { x: 1312, y: 373, id: 54 },
  { x: 1380, y: 380, id: 102 },
  { x: 1204, y: 401, id: 86 },
  { x: 1140, y: 391, id: 83 },
  { x: 1412, y: 395, id: 104 },
  { x: 1379, y: 430, id: 101 },
  { x: 1365, y: 525, id: 103 },
  { x: 1290, y: 522, id: 97 },
  { x: 1230, y: 540, id: 92 },
  { x: 1235, y: 598, id: 99 },
  { x: 1340, y: 685, id: 117 },
  { x: 1210, y: 695, id: 118 },
  { x: 1129, y: 643, id: 87 },
  { x: 1193, y: 623, id: 100 },
  // { x: 1121, y: 584, id: 96 },
  { x: 1250, y: 785, id: 123 },
  { x: 1348, y: 820, id: 125 },
  { x: 1252, y: 812, id: 124 },
  { x: 1413, y: 820, id: 128 },
  { x: 1400, y: 864, id: 127 },
  { x: 1267, y: 864, id: 126 },
  { x: 1138, y: 794, id: 121 },
  { x: 1008, y: 867, id: 119 },
  { x: 1098, y: 854, id: 120 },
  { x: 1015, y: 805, id: 115 },
  { x: 980, y: 750, id: 114 },
  { x: 920, y: 785, id: 113 },
  { x: 945, y: 855, id: 116 },
  { x: 1000, y: 520, id: 93 },
  { x: 995, y: 578, id: 95 },
  { x: 945, y: 598, id: 122 },
  { x: 1045, y: 585, id: 94 },
  { x: 1115, y: 520, id: 94 },
  { x: 862, y: 733, id: 112 },
  { x: 840, y: 854, id: 144 },
  { x: 790, y: 760, id: 143 },
  { x: 53, y: 270, id: 21 },
  { x: 120, y: 242, id: 22 },
  { x: 232, y: 240, id: 23 },
  { x: 320, y: 235, id: 25 },
  { x: 465, y: 253, id: 27 },
  { x: 525, y: 213, id: 28 },
  { x: 650, y: 241, id: 29 },
  { x: 655, y: 301, id: 45 },
  { x: 649, y: 391, id: 46 },
  { x: 795, y: 313, id: 47 },
  { x: 893, y: 253, id: 30 },
  { x: 916, y: 319, id: 49 },
  { x: 570, y: 280, id: 44 },
  { x: 376, y: 235, id: 26 },
  { x: 272, y: 318, id: 42 },
  { x: 55, y: 400, id: 40 },
  { x: 116, y: 362, id: 41 },
  { x: 185, y: 402, id: 72 },
  { x: 195, y: 462, id: 145 },
  { x: 108, y: 455, id: 71 },
  { x: 395, y: 332, id: 98 },
  { x: 396, y: 380, id: 43 },
  { x: 440, y: 393, id: 55 },
  { x: 496, y: 418, id: 56 },
  { x: 425, y: 438, id: 57 },
  { x: 454, y: 430, id: 58 },
  { x: 513, y: 441, id: 60 },
  { x: 538, y: 420, id: 61 },
  { x: 562, y: 440, id: 59 },
  { x: 565, y: 418, id: 62 },
  { x: 585, y: 400, id: 63 },
  { x: 617, y: 460, id: 152 },
  { x: 315, y: 390, id: 111 },
  { x: 617, y: 414, id: 151 },
  { x: 667, y: 414, id: 64 },
  { x: 690, y: 429, id: 65 },
  { x: 710, y: 409, id: 149 },
  { x: 762, y: 452, id: 66 },
  { x: 763, y: 390, id: 48 },
  { x: 805, y: 389, id: 48 },
  { x: 978, y: 457, id: 68 },
  { x: 915, y: 473, id: 81 },
  { x: 1035, y: 453, id: 82 },
  { x: 900, y: 523, id: 80 },
  { x: 868, y: 543, id: 68 },
  { x: 693, y: 570, id: 78 },
  { x: 697, y: 533, id: 24 },
  { x: 772, y: 509, id: 150 },
  { x: 738, y: 499, id: 150 },
  { x: 627, y: 569, id: 79 },
  { x: 547, y: 580, id: 77 },
  { x: 510, y: 568, id: 76 },
  { x: 454, y: 519, id: 74 },
  { x: 383, y: 510, id: 73 },
  { x: 370, y: 575, id: 73 },
  { x: 513, y: 523, id: 75 },
  { x: 264, y: 577, id: 85 },
  { x: 80, y: 590, id: 84 },
  { x: 50, y: 535, id: 68 },
  { x: 79, y: 487, id: 69 },
  { x: 128, y: 535, id: 68 },
  { x: 100, y: 488, id: 70 },
  { x: 20, y: 562, id: 67 },
  { x: 35, y: 638, id: 105 },
  { x: 190, y: 625, id: 87 },
  { x: 351, y: 610, id: 88 },
  { x: 518, y: 650, id: 89 },
  { x: 1243, y: 486, id: 91 },
  { x: 917, y: 632, id: 90 },
  { x: 693, y: 705, id: 142 },
  { x: 612, y: 748, id: 139 },
  { x: 477, y: 814, id: 136 },
  { x: 476, y: 846, id: 141 },
  { x: 548, y: 733, id: 137 },
  { x: 615, y: 656, id: 138 },
  { x: 300, y: 710, id: 132 },
  { x: 255, y: 790, id: 133 },
  { x: 269, y: 810, id: 134 },
  { x: 314, y: 679, id: 131 },
  // { x: 146, y: 341, id: 135 },
  { x: 169, y: 779, id: 130 },
  { x: 124, y: 690, id: 110 },
  { x: 35, y: 722, id: 129 },
  { x: 22, y: 770, id: 140 },
  { x: 212, y: 672, id: 106 },
  { x: 413, y: 666, id: 107 },
  { x: 368, y: 689, id: 108 },
  { x: 421, y: 710, id: 109 },
  { x: 470, y: 720, id: 146 }
];
